// src/Contact.js

import React, {useEffect} from 'react';
import { Container, Grid, Paper, Typography, Button } from '@material-ui/core';
import portfolioImg20 from "../images/qrposter.png";

const Contact = () => {
  const mobileNumber = 'suman21ab@gmail.com'; // Replace with your mobile number
  const qrCodeImageUrl = portfolioImg20; // Replace with your QR code image URL



  return (
    <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
      <Paper elevation={3} style={{ padding: '2rem' }}>
        <Typography variant="body1" align="center">
          <img src={qrCodeImageUrl} alt="QR Code" style={{ width: '100%', }} />
        </Typography>
          
        <Typography variant="body1" align="center" style={{ marginTop: '1rem' }}>
          Take a screenshot and share it on social media!
        </Typography>
        <Typography variant="body1" align="center" style={{ marginTop: '1rem' }}>
            Complain: {mobileNumber}
        </Typography>
          
      </Paper>
    </Container>
  );
};

export default Contact;
