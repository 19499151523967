// src/CartContext.js
import React, { createContext, useState, useContext } from 'react';

// Create a Context for the cart
const CartContext = createContext();

// Create a Provider component
export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState([]);
    const [location, setLocation] = useState({ latitude: null, longitude: null });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const addToCart = (product) => {
        if(cart.find((currentItem)=> currentItem.post._id === product.post._id)){
            let updatedProduct = cart.map((currentItem) =>{
                if(currentItem.post._id=== product.post._id){
                    let newAmount = currentItem.amount + product.amount;
                    return {post: currentItem.post, amount:newAmount};
                    

                }else{
                    return currentItem;

                }

            });

            setCart(updatedProduct);

        }else{
            setCart((prevCart) => [...prevCart, product]);

        }     
    };

    const handleGetLocation = () => {
        setLoading(true);  // Set loading state
    
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setLocation({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
              });
              setLoading(false); // Reset loading state
            },
            (err) => {
              setError(err.message);
              setLoading(false); // Reset loading state
            }
          );
        } else {
          setError('Geolocation is not supported by this browser.');
          setLoading(false); // Reset loading state
        }
      };

    const removeItem = (id) => {
        let updatedCart = cart.filter((currentItem)=> currentItem.post._id !== id);
        setCart(updatedCart);
        //post._id
    };

    const emptyCart = () => {
      
      setCart([]);
      //post._id
  };

    return (
        <CartContext.Provider value={{ cart, addToCart, removeItem , handleGetLocation, error, location , emptyCart}}>
            {children}
        </CartContext.Provider>
    );
};

// Custom hook to use the Cart Context
export const useCart = () => useContext(CartContext);
