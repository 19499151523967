import React from 'react';
import { useEffect, useState } from "react";
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import alarmSound from './notify.mp3';

import { Button, CircularProgress } from '@material-ui/core';
import { Link} from 'react-router-dom';


import TableRow from '@material-ui/core/TableRow';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { IconButton } from "@material-ui/core";

import TextField from '@material-ui/core/TextField';

import io from 'socket.io-client';

import {useNavigate} from 'react-router-dom';

const socket = io('https://www.lelofood.xyz');

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  root: {
    width: '90%',
    marginLeft: '20px',
    
  },
  container: {
    maxHeight: 620,
  },
}));


const currencies = [
  {
    value: 'Reject',
    label: 'Reject',
  },
  {
    value: 'Order placed',
    label: 'Order placed',
  },
  {
    value: 'Order confirmed',
    label: 'Order confirmed',
  },
  {
    value: 'Cooking',
    label: 'Cooking',
  },
  {
    value: 'Out for delivery',
    label: 'Out for delivery',
  },
  {
    value: 'Complete',
    label: 'Complete',
  },
];

const deliveryBoy = [
  {
    value: 'none',
    label: 'none',
  },
  {
    value: 'Shivpujan',
    label: 'Shivpujan',
  },
  {
    value: 'Rohit',
    label: 'Rohit',
  },
  {
    value: 'Sonu',
    label: 'Sonu',
  }
];





export default function Admin({isearning}) {
  const [orders, setorders] = useState([]);

  const [id, setid] = useState();

  const [user, setuser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [isstatus, issetStatus] = useState();
  //{user.result.name}
  const goToPage = useNavigate();

  const classes = useStyles();
    //const [updatedorder, setupdatedorder] = React.useState();
  
    const handleChange = async (event) => {
    
      const updatedstatus = {
        _id: id._id,
        mobile: id.mobile,
        status: event.target.value,
        address: id.address,
        GPS: id.GPS,
        cart: id.cart,
        createdAt: id.createdAt,
        __v:id.__v,
        writecake:id.writecake,
        DeliveryDateTime:id.DeliveryDateTime,
        seller: id.seller,
        deliveryBoy: id.deliveryBoy
  
      };

      try {
        
        const res = await axios.patch(`https://www.lelofood.xyz/posts/orders/${id._id}`, updatedstatus);
        //console.log(res.data);
        socket.emit('updateStatus', res.data);
        //console.log(orders);  
        //setupdatedorder(res.data);
        setorders(orders.map((post) => (post._id === res.data._id ? res.data : post)));
      } catch (err) {
        console.log(err);
      }

    };

     const handleChange2 = async (event) => {
    
      const updatedstatus = {
        _id: id._id,
        mobile: id.mobile,
        status: id.status,
        address: id.address,
        GPS: id.GPS,
        cart: id.cart,
        createdAt: id.createdAt,
        __v:id.__v,
        writecake:id.writecake,
        DeliveryDateTime:id.DeliveryDateTime,
        seller: id.seller,
        deliveryBoy: event.target.value
  
      };

      try {
        
        const res = await axios.patch(`https://www.lelofood.xyz/posts/orders/${id._id}`, updatedstatus);
        socket.emit('updateStatus', res.data);
        console.log(res.data);
        //console.log(orders);  
        //setupdatedorder(res.data);
        setorders(orders.map((post) => (post._id === res.data._id ? res.data : post)));
      } catch (err) {
        console.log(err);
      }

    };

    useEffect(() => {
      if (!user) {
        goToPage('/auth');
        return;
      }
    }, [user]);

    useEffect(() => {
      // Listen for initial orders when the component mounts

      isearning && socket.emit('addUser', user?.result?.name);

      socket.on('initialOrders', (initialOrders) => {
        setorders(initialOrders);
      });
  
      // Listen for new orders being broadcast
      socket.on('orderUpdated', (order) => {
        setorders((prevOrders) => [...prevOrders, order]);
        
        const audio = new Audio(alarmSound);
        audio.play();
        
      });

      socket.on('orderDeleted', (id) => {
        setorders(prevOrders => prevOrders.filter(order => order._id !== id));
        
      });

      socket.on('statusUpdated', (updatedOrder) => {
        
        setorders(prevOrders => 
          prevOrders.map(order => 
              order._id === updatedOrder._id ? updatedOrder : order
          )
        );
        
      }); 

      
  
      // Cleanup on unmount
      return () => {
        socket.off('initialOrders');
        socket.off('orderUpdated');
      };
    }, []);

    

    useEffect(() => {
      // Define the fetch function
      const fetchData = async () => {
        issetStatus(true);
        try {
          //const response = await axios.get('http://localhost:5000/posts/orders');
          
          if(isearning){
            const response = await axios.get(`https://www.lelofood.xyz/posts/sellerorders/${user.result.name}`);
          //setorders(response.data);
          console.log("sold");
          issetStatus(false);
          setorders(response.data);

          }
          if(!isearning){
            const res = await axios.get(`https://www.lelofood.xyz/posts/earningbyseller/${user.result.name}`);
          //setorders(response.data);
          console.log("earn");
          issetStatus(false);
          setorders(res.data);

          }
        } catch (err) {
          console.log(err);
          
        }
      };
  
      // Call the fetch function
      fetchData();
    }, [isearning]); 

  

   
          
            
      

   

    const calculateTotal = (cart) => {
      return cart.reduce((total, item) => total + item.post.price * item.amount, 0); 
        };

    const deleteOrder = async(id) => {

      try {
        const response = await axios.post("https://www.lelofood.xyz/posts/earning", id);
        const res = await axios.delete(`https://www.lelofood.xyz/posts/orders/${id._id}`);
        
        socket.emit('deleteOrder', id);
        
        //console.log(res);
        //console.log(orders);  
        //setupdatedorder(res.data);
        if(res.data){
          setorders(orders.filter((post) => post._id !== id._id));
        }
        
      } catch (err) {
        
        console.log(err);
      }
           
    };

    const deleteSoldOrder = async(id) => {

      try {
        
        const res = await axios.delete(`https://www.lelofood.xyz/posts/sold/${id._id}`);
        //console.log(res);
        //console.log(orders);  
        //setupdatedorder(res.data);
        if(res.data){
          setorders(orders.filter((post) => post._id !== id._id));
        }
        
      } catch (err) {
        
        console.log(err);
      }
           
    };
   
   
    
  return (
    <Paper className={classes.root}>
      
     
        <div style={{display: "flex", flexDirection: "row",alignItems:"center"}}>
        {isearning ? <h1>Total orders: {orders.length}</h1> : <h1>Sold Rs. {orders.reduce((acc, row) => acc + calculateTotal(row.cart), 0)}</h1>}
        
        {isearning ? 
        <Button component ={Link}  to="/earning" variant="contained" color ="primary" size="small" 
        style={{marginLeft: "10px", backgroundColor :"#FF6600", color:"white"}}>Sold</Button>
          : <Button component ={Link}  to="/seller" variant="contained" color ="primary" size="small" 
          style={{marginLeft: "10px",backgroundColor :"#FF6600", color:"white"}}>Live Orders</Button>}       
        </div>
        {
        isstatus ? <div style={{marginLeft: "45%", marginTop: "25px"}}><CircularProgress/></div> 

        :

        <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" style={{marginBottom: "30vh"}}>
          <TableHead >
            <TableRow >
              {
              /*
              columns.map((column) => (
                user?.result?.name !== 'suman' && column.label !=='Seller' &&
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth , backgroundColor:"rgb(240, 240, 240)", color: "black", fontSize: "15px", fontWeight: "bold"}}
                  
                >
                  {column.label}
                </TableCell>
                
              ))*/}

              
                <TableCell
                  
                  style={{ minWidth: 70 , backgroundColor:"rgb(240, 240, 240)", color: "black", fontSize: "15px", fontWeight: "bold"}}
                  
                >
                  Orders
                </TableCell>

             
                <TableCell
                  
                  style={{ minWidth: 50 , backgroundColor:"rgb(240, 240, 240)", color: "black", fontSize: "15px", fontWeight: "bold"}}
                  
                >
                  DeliveryBoy
                </TableCell>

              
              {
              (user?.result?.name === 'suman'  || user?.result?.name === 'sender' || user?.result?.name === 'sender2' || user?.result?.name === 'sender3') &&
                <TableCell
                  
                  style={{ minWidth: 70 , backgroundColor:"rgb(240, 240, 240)", color: "black", fontSize: "15px", fontWeight: "bold"}}
                  
                >
                  Seller
                </TableCell>

              }
             
                <TableCell
                  
                  style={{ minWidth: 50 , backgroundColor:"rgb(240, 240, 240)", color: "black", fontSize: "15px", fontWeight: "bold"}}
                  
                >
                  Status
                </TableCell>

              
              {
              (user?.result?.name === 'cake' || user?.result?.name === 'suman'  || user?.result?.name === 'sender' || user?.result?.name === 'sender2' || user?.result?.name === 'sender3') &&
                <TableCell
                  
                  style={{ minWidth: 30 , backgroundColor:"rgb(240, 240, 240)", color: "black", fontSize: "15px", fontWeight: "bold"}}
                  
                >
                  DeliveryDateTime
                </TableCell>

              }
              
              {
              (user?.result?.name === 'cake' || user?.result?.name === 'suman'  || user?.result?.name === 'sender' || user?.result?.name === 'sender2' || user?.result?.name === 'sender3') &&
                <TableCell
                  
                  style={{ minWidth: 150 , backgroundColor:"rgb(240, 240, 240)", color: "black", fontSize: "15px", fontWeight: "bold"}}
                  
                >
                  WriteOnCake
                </TableCell>

              }
              
             
                <TableCell
                  
                  style={{ minWidth: 30 , backgroundColor:"rgb(240, 240, 240)", color: "black", fontSize: "15px", fontWeight: "bold"}}
                  
                >
                  Order Time
                </TableCell>

              
              
                <TableCell
                  
                  style={{ minWidth: 70 , backgroundColor:"rgb(240, 240, 240)", color: "black", fontSize: "15px", fontWeight: "bold"}}
                  
                >
                  Mobile
                </TableCell>

              
                <TableCell
                  
                  style={{ minWidth: 170 , backgroundColor:"rgb(240, 240, 240)", color: "black", fontSize: "15px", fontWeight: "bold"}}
                  
                >
                  Address
                </TableCell>

              
               {
              (user?.result?.name === 'suman' || user?.result?.name === 'sender' || user?.result?.name === 'sender2' || user?.result?.name === 'sender3') &&
                <TableCell
                  
                  style={{ minWidth: 170 , backgroundColor:"rgb(240, 240, 240)", color: "black", fontSize: "15px", fontWeight: "bold"}}
                  
                >
                  GPS
                </TableCell>

              }
              
              
                <TableCell
                  
                  style={{ minWidth: 30 , backgroundColor:"rgb(240, 240, 240)", color: "black", fontSize: "15px", fontWeight: "bold"}}
                  
                >
                  Delete
                </TableCell> 

              
            </TableRow>
          </TableHead>
          <TableBody >
            {orders.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                  
                      <TableCell>
                        {row._id}
                        {row.cart.map((item, index) => (
                       
                            <div key={index} style={{display: "flex", flexDirection: "row",justifyContent:"space-between", gap:"10px"}}>

                                 <div style={{fontSize: "10px"}}>{item.post.name}</div>
                                 <div style={{fontSize: "10px"}}>{item.amount}</div>
                                 <div style={{fontSize: "10px"}}>Rs. {item.post.price * item.amount}</div>
                                   
                            </div>
                         ))}
                          
                         <div style={{fontSize: "12px", fontWeight: "bold"}}>Total Rs. {calculateTotal(row.cart)}</div>
                      </TableCell>
                      {isearning ? <TableCell >
                          <form className={classes.root} noValidate autoComplete="off">
                              <div onClick={() => setid(row)}>
                                <TextField
                                  id="outlined-select-currency-native"
                                  select
                                  label="DeliveryBoy"
                                  value={row.deliveryBoy}
                                  onChange={handleChange2}
                                  
                                  SelectProps={{
                                    native: true,
                                  }}
                                  
                                  variant="outlined"

                                  style={{ width: 140}}
                                >
                                  {deliveryBoy.map((option) => (
                                    <option key={option.value} value={option.value}>
                                      {option.label}
                                    </option>
                                  ))}
                                </TextField>
                              </div>
                          </form>
                      </TableCell> : <TableCell >
                        {row.deliveryBoy}
                      </TableCell>}

                      {(user?.result?.name === 'suman' || user?.result?.name === 'sender' || user?.result?.name === 'sender2' || user?.result?.name === 'sender3' ) &&
                      <TableCell >
                        {row.seller}
                      </TableCell>}

                      {isearning ? <TableCell >
                          <form className={classes.root} noValidate autoComplete="off">
                              <div onClick={() => setid(row)}>
                                <TextField
                                  id="outlined-select-currency-native"
                                  select
                                  label="status"
                                  value={row.status}
                                  onChange={handleChange}
                                  
                                  SelectProps={{
                                    native: true,
                                  }}
                                  
                                  variant="outlined"

                                  style={{ width: 140}}
                                >
                                  {currencies.map((option) => (
                                    <option key={option.value} value={option.value}>
                                      {option.label}
                                    </option>
                                  ))}
                                </TextField>
                              </div>
                          </form>
                      </TableCell> : <TableCell >
                        {row.status}
                      </TableCell>}

                      {(user?.result?.name === 'suman' || user?.result?.name === 'sender' || user?.result?.name === 'sender2' || user?.result?.name === 'sender3' || user?.result?.name === 'cake') &&
                      <TableCell >
                        {row.DeliveryDateTime}
                      </TableCell>}
                      
                      {(user?.result?.name === 'suman' || user?.result?.name === 'sender' || user?.result?.name === 'sender2' || user?.result?.name === 'sender3' || user?.result?.name === 'cake') &&
                      <TableCell >
                        {row.writecake}
                      </TableCell>}
                      
                     
                      <TableCell  >
                        {row.createdAt}
                      </TableCell>
                      <TableCell >
                        {row.mobile}
                      </TableCell>

                      <TableCell  >
                        {row.address} 
                      </TableCell>

                      {(user?.result?.name === 'suman' || user?.result?.name === 'sender' || user?.result?.name === 'sender2' || user?.result?.name === 'sender3') &&
                      <TableCell  >
                        {row.GPS}
                      </TableCell>} 
                     
                      {isearning === true ? <TableCell  >
                             <IconButton style={{color: "red"}} size="large" onClick={() => deleteOrder(row)} >
                                   <DeleteForeverIcon />
                             </IconButton>
                      </TableCell>
                      : <TableCell  >
                          <IconButton style={{color: "red"}} size="large" onClick={() => deleteSoldOrder(row)} >
                            <DeleteForeverIcon />
                          </IconButton>
                        </TableCell>}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>


        }
      
     
      
      
     
    </Paper>
  );
}


