import React, {useState, useEffect} from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {AppBar, Avatar, Typography, Toolbar, Button, useTheme, useMediaQuery, IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import decode from 'jwt-decode';
//import { getPosts } from '../../actions/posts';

import useStyles from './styles'; 
import memories from '../../images/socialMedia.png';
import MenuIcon from '@material-ui/icons/Menu';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import HomeIcon from '@material-ui/icons/Home';

import { useCart } from '../../cartContext/CartContext';



const Navbar =()=>{

const { emptyCart } = useCart();

const classes = useStyles();
//const user1 = null; // check it user in usestate below

const [user, setuser] = useState(JSON.parse(localStorage.getItem('profile')));
const [openDrawer, setOpenDrawer] = useState(false);

const dispatch = useDispatch();
const history = useNavigate();
const location = useLocation();

const Logout = () => {
    dispatch({type: 'LOGOUT'});
    setOpenDrawer(!openDrawer);
    history('/');
    emptyCart();
    //dispatch(getPosts());
    setuser(null);
};

useEffect(() => {
    const token = user?.token;

    if(token){
        const decodedToken = decode(token);
        if(decodedToken.exp * 1000 < new Date().getTime()) Logout();

    }

    setuser(JSON.parse(localStorage.getItem('profile')));
// eslint-disable-next-line
  },[location]);


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  //console.log("isMobile");
  //console.log(isMobile);

return (

    isMobile ? ( 
        <>
                <AppBar className={classes.appBar1} position = "static" color="inherit"  >
                    <div className={classes.brandContainer} >
                        <Typography  component={Link} to="/" onClick= {()=>{emptyCart()}} className={classes.heading} variant = "h5" align="center" >www.lelofood.com</Typography>
    
                        <img  className={classes.image} src={memories} alt="icon" height="50" />
                    </div>
                    
                    {openDrawer ? 
                    <IconButton style={{color: "red"}} onClick={() => setOpenDrawer(!openDrawer)} size="large">
                        <CloseOutlinedIcon fontSize="large"  />
                    </IconButton>
                    : <IconButton onClick={() => setOpenDrawer(!openDrawer)} size="large">
                        <MenuIcon fontSize="large"/>
                      </IconButton>
                    }
                    
                    
                </AppBar>

                {openDrawer ? (
                    <div className={classes.toolbar1}>
        
                        {user ? (
                                <div className ={classes.profile2}>
                                    <Button variant="contained" className ={classes.Logout} onClick ={Logout} style={{marginTop: '10px', backgroundColor :"#FF6600", color:"white"}} >Logout</Button>

                                    <div className ={classes.profile3}>
                                        <Avatar className={classes.purple} alt ={user.result.name} src={user.result.imageUrl}>{user.result.name.charAt(0)} </Avatar>   
                                        <Typography className= {classes.userName} variant= "h5">{user.result.name}</Typography>
                                        
                                    </div>
                                    <Button component ={Link} onClick ={()=> setOpenDrawer(!openDrawer)} to="/allorders" variant="contained" color ="primary" style={{marginTop: '15px', backgroundColor :"#FF6600", color:"white"}}>All Orders</Button>
                                    <Button component ={Link} onClick ={()=> setOpenDrawer(!openDrawer)} to="/status" variant="contained" color ="primary" style={{marginTop: '15px', backgroundColor :"#FF6600", color:"white"}}>Track Order</Button>
                                    <Button component ={Link} onClick ={()=> setOpenDrawer(!openDrawer)} to="/seller" variant="contained"  style={{marginTop: '15px', backgroundColor :"#FF6600", color:"white"}}>Seller</Button>
                                    <Button component ={Link} onClick ={()=> setOpenDrawer(!openDrawer)} to="/contact" variant="contained" color ="primary" style={{marginTop: '15px', backgroundColor :"#FF6600", color:"white"}}>QR Contact</Button>
                               
                            
                                </div>
                        ):(
                                <div className ={classes.profile2}>
                                <Button component ={Link} onClick ={()=> {setOpenDrawer(!openDrawer);emptyCart()}} to="/" variant="contained" style={{marginTop: '15px',  backgroundColor :"#FF6600", color:"white"}}><HomeIcon style={{marginRight: '5px'}}/>home</Button>
                                <Button component ={Link} onClick ={()=> setOpenDrawer(!openDrawer)} to="/allorders" variant="contained"  style={{marginTop: '15px', backgroundColor :"#FF6600", color:"white"}}>All Orders</Button>
                                <Button component ={Link} onClick ={()=> setOpenDrawer(!openDrawer)} to="/status" variant="contained" color ="primary" style={{marginTop: '15px', backgroundColor :"#FF6600", color:"white"}}>Track Order</Button>
                                <Button component ={Link} onClick ={()=> setOpenDrawer(!openDrawer)} to="/seller" variant="contained"  style={{marginTop: '15px', backgroundColor :"#FF6600", color:"white"}}>Seller</Button>
                                <Button component ={Link} onClick ={()=> setOpenDrawer(!openDrawer)} to="/contact" variant="contained" color ="primary" style={{marginTop: '15px', backgroundColor :"#FF6600", color:"white"}}>QR Contact</Button>
                                </div>
                        )}
                    </div>
                     ) : (null)
                    }
        

        </>
    ):(
        <AppBar className={classes.appBar} position = "static" color="inherit">
            <div className={classes.brandContainer}>
            <Typography component={Link} to="/" onClick= {()=>{emptyCart()}} className={classes.heading} variant = "h3" align="center" >www.lelofood.com</Typography>
            <img className={classes.image} src={memories} alt="icon" height="50" />
            </div>

            <Toolbar className={classes.toolbar}>
                {user ? (
                <div className ={classes.profile}>
                    <Avatar className={classes.purple} alt ={user.result.name} src={user.result.imageUrl}>{user.result.name.charAt(0)} </Avatar>
                    <Typography className= {classes.userName} variant= "h6">{user.result.name}</Typography>
                
                    <Button variant="contained" className ={classes.Logout} color = "secondary" onClick ={Logout} >Logout</Button>
                    <Button component ={Link} onClick ={()=> setOpenDrawer(!openDrawer)} to="/status" variant="contained" color ="primary" style={{marginLeft:"5px",backgroundColor :"#FF6600", color:"white"}}>Track Order</Button>
               
                </div>
                ):(
                    <>
                <Button component ={Link} to="/auth" variant="contained"  style={{backgroundColor :"#FF6600", color:"white"}}>sign in</Button>
                <Button component ={Link} onClick ={()=> setOpenDrawer(!openDrawer)} to="/status" variant="contained" color ="primary" style={{marginLeft:"5px",backgroundColor :"#FF6600", color:"white"}}>Track Order</Button>
                </>
                )}
            </Toolbar>
        </AppBar>

    )

);

};

export default Navbar;