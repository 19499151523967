import React, { useState, useEffect } from "react";
import "./Status.css";
//import emailgif from "./email-gif1.gif";

//import {useNavigate} from 'react-router-dom';

import {  Button, CircularProgress } from "@material-ui/core";
import axios from "axios";

import OrderPlced from '@material-ui/icons/AssignmentTurnedInOutlined';
import OrderConfirmed from '@material-ui/icons/DoneAllOutlined';
import Cooking from '@material-ui/icons/LocalDiningOutlined';
import OutforDelivery from '@material-ui/icons/MotorcycleOutlined';
import Complete from '@material-ui/icons/EmojiEmotionsOutlined';
import { useNavigate} from 'react-router-dom';


import Input from "../Input";
const initialState = {
  Name: ""
};

const Status = () => {
  const goToPage = useNavigate();
  const [formData, setFormData] = useState(initialState);
  const [moberror, setmobError] = useState();
  const [loading, setloading] = useState(false);
  const [loadingerror, setloadingerror] = useState(false);
  const [status, setStatus] = useState([]);
  const [isstatus, issetStatus] = useState();

  const validateMobileNumber = (number) => {
    // Example validation: 10 digits mobile number
    const regex = /^\d{10}$/;
    return regex.test(number);
  };

  const handleChange = (e) => {
    
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
        if (!validateMobileNumber(formData.Name)){
          setmobError("Error: Please enter 10 digit Mobile no.");
        }
        else{
          issetStatus(true);
          setmobError();

              try {
                setloading(true);
                const res = await axios.get(`https://www.lelofood.xyz/posts/orders/${formData.Name}`);
                issetStatus(false);
                setStatus(res.data);
                
                //setStatus(res.data[0].status);
                
                console.log(res.data);
        
              } catch (err) {
                setloadingerror(true);
                console.log(err);
              }

          
        } 
  };



 
  return (
    <div className="chatBox" style={{overflow:"scroll", height:"85dvh"}}>
      
      <form onSubmit={handleSubmit} >
      <div className="input"> 
     
        <Input
                      name="Name"
                      label="Enter Correct Mobile No."
                      rows="1"
                      autoFocus
                      handleChange={handleChange}  
                      inputwidth= {210}        
        />
        <Button
                      type="submit"
                      variant="contained"
                      color="primary"

                        style={{fontWeight:"600", backgroundColor: '#FF6600'}}
                        

                    >
                      Track
        </Button>
        
      </div>
      {moberror  && (
                    <div  style={{color: "red", fontWeight:"600", marginLeft: "8%"}}>{moberror}</div>     
      )}
      <div style={{marginLeft: "10%", marginTop: "25px"}}>Always Click Track button for latest update</div>
      {
        isstatus && <div style={{marginLeft: "45%", marginTop: "25px"}}><CircularProgress/></div> 
      }
      
      </form>
      
        
        {status.map((item) => (

          <div className="contactContainer">
          <div className="status" ><h3> Shop: {item.seller}</h3></div>
          
          <span style={{marginLeft: "30%"}}>{item.cart.map((item) => (
              <span key={item.post._id}>{item.post.name}. </span>
            ))}</span>

            {item.status === "Reject" ?
                <div className="status1" style={{marginTop: "12px"}}>
                   <div className="dot1">
                   </div>
                   Rejected, not picked call, or out of stock
                </div>
             :  
                <>
                          {item.status === "Order placed" ? ( <div className="status1">
                      <OrderPlced />
                      <div className="dot1"></div>
                      Order placed 
                    </div>):( <div className="status">
                      <OrderPlced />
                      <div className="dot"></div>
                      Order placed 
                    </div>)}
                    
                    
                    <div className="divider">
                      <div className="innerdivider1"></div>
                    </div>
                    {item.status === "Order confirmed" ? (<div className="status1">
                      <OrderConfirmed />
                      <div className="dot1"></div>
                      Order confirmed
                    </div>):(<div className="status">
                      <OrderConfirmed />
                      <div className="dot"></div>
                      Order confirmed
                    </div>)}
                    
                    <div className="divider">
                      <div className="innerdivider1"></div>
                    </div>
                    {
                      item.status === "Cooking" ? (<div className="status1">
                        <Cooking />
                        <div className="dot1"></div>
                        Cooking
                      </div>):(<div className="status">
                      <Cooking />
                      <div className="dot"></div>
                      Cooking
                    </div>)
                    }

                    <div className="divider">
                      <div className="innerdivider1"></div>
                    </div>
                    {item.status === "Out for delivery" ? (<div className="status1">
                      <OutforDelivery />
                      <div className="dot1"></div>
                      Out for delivery
                    </div>):(<div className="status">
                      <OutforDelivery />
                      <div className="dot"></div>
                      Out for delivery
                    </div>)}
                    
                    <div className="divider">
                      <div className="innerdivider1"></div>
                    </div>
                    {item.status === "Complete" ? (<div className="status1">
                      <Complete />
                      <div className="dot1"></div>
                      Complete
                    </div>):(<div className="status">
                      <Complete />
                      <div className="dot"></div>
                      Complete
                    </div>)}
                
                </>
             
             }
    
        </div>

        ))
        }
        
             
           
    </div>
  );
};
export default Status;
