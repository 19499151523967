import React from "react";
import { TextField, Grid } from "@material-ui/core";

const Input = ({ name, handleChange, label, autofocus, type, rows, inputwidth }) => (
  <Grid item>
    <TextField
      name={name}
      onChange={handleChange}
      variant="outlined"
      required
      fullWidth
      label={label}
      autoFocus={autofocus}
      type={type}
      multiline
      rows={rows}
      style={{ width: inputwidth}}
       
    />
  </Grid>
);
export default Input;


      //style={{ width: 320}}
  
