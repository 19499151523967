 import React, {useEffect} from "react";
 import { Container,  Grow, Grid, useTheme, useMediaQuery, Button } from '@material-ui/core';
 //import { useDispatch } from 'react-redux';
 import { Link, useLocation} from 'react-router-dom';
 import { useCart } from '../../cartContext/CartContext';

 //import { getPosts } from '../../actions/posts';
 import Posts from "../Posts/Posts";
//import Form from "../Form/Form";
import './Home.css';
//import axios from "axios";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';




 const Home = ({post23, iscakebill, ishome, setseller, sellername}) =>{

    const { cart } = useCart();
    const { emptyCart } = useCart();
    const location = useLocation();

    useEffect(() => {
      // Check if the current path is the home page
      if (location.pathname === '/') {
        emptyCart();
      }
    }, [location]); 

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  
    //console.log("isMobile");
    //console.log(isMobile);

    const calculateTotal = () => {
      
      return cart.reduce((total, item) => total + item.amount, 0); 
    };

  

    return(
    
        <Grow in>
        <Container>
          <Grid container  justify="space-between" alignItems="stretch" spacing={3}>
            {/*<Grid item xs={12} sm={4}>
              <Form currentId={currentId} setCurrentId={setCurrentId} />
            </Grid>*/}
            {
              ishome ? emptyCart : null
            }
            
           
            {isMobile ? (
              <>
              <Grid className="notscrollbar" style={{maxHeight: "90vh", overflowY: "scroll"}} item xs={12} sm={7}>
                <Posts  post23={post23} ishome={ishome}/>
              </Grid>
              {!ishome? 
          
              (iscakebill ? 
                <>
              <Button component ={Link} onClick ={()=> {emptyCart()}} to="/" variant="contained" style={{marginTop: '76vh', fontWeight:"800", marginLeft: "12vw", backgroundColor: '#FF6600', position:"absolute", color:"white"}}>Back</Button>
              <Button onClick={setseller(sellername)} component ={Link} to="/cakebill" variant="contained" color ="primary" style={{marginTop: '75vh', fontWeight:"800", marginLeft: "42vw", backgroundColor: '#FF6600', position:"absolute"}}><span style={{color:"white",backgroundColor: '#FF6600', marginRight:"10px", fontSize:"20px"}}>{calculateTotal()}</span>GO TO CART<ShoppingCartIcon/></Button>
              </>
              : 
              <>
              <Button component ={Link} onClick ={()=> {emptyCart()}} to="/" variant="contained" style={{marginTop: '76vh', fontWeight:"800", marginLeft: "12vw", backgroundColor: '#FF6600', position:"absolute", color:"white"}}>Back</Button>
              <Button onClick={()=>setseller(sellername)} component ={Link} to="/contactus" variant="contained" color ="primary" style={{marginTop: '75vh', fontWeight:"800", marginLeft: "42vw", backgroundColor: '#FF6600', position:"absolute"}}><span style={{color:"white",backgroundColor: '#FF6600', marginRight:"10px", fontSize:"20px"}}>{calculateTotal()}</span>GO TO CART<ShoppingCartIcon/></Button>
              </>
              )
              :null
            }
              </>             
            ) : (
              <>
              <Grid className="notscrollbar"  style={{maxHeight: "90vh", overflowY: "scroll"}} item xs={12} sm={7} lg={12}>
                <Posts  post23={post23} ishome={ishome}/>
              </Grid> 
              <Button onClick={()=>setseller(sellername)} component ={Link} to="/contactus" variant="contained" color ="primary" style={{marginTop: '70vh', fontWeight:"800", marginLeft: "40vw", backgroundColor: '#FF6600', position:"absolute",}}><span style={{color:"white",backgroundColor: '#FF6600', marginRight:"10px", fontSize:"20px"}}>{calculateTotal()}</span>GO TO CART<ShoppingCartIcon fontSize="large"/></Button>
              </>
            )}

                       
          </Grid>
        </Container>
      </Grow>

    );
  };

 export default Home;