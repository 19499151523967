import React ,{ useState, useEffect } from "react";
import "./Allorders.css";
//import emailgif from "./email-gif1.gif";

//import {useNavigate} from 'react-router-dom';

import {  Button, CircularProgress } from "@material-ui/core";
import { useNavigate} from 'react-router-dom';


//import { useCart } from '../../cartContext/CartContext';
import Input from "../Input";

import axios from "axios";

const initialState = {
  Name: ""
};

const Allorders = () => {

  const goToPage = useNavigate();

  const [formData, setFormData] = useState(initialState);
  const [orderbymob, setorderbymob] = useState();
  //const { cart } = useCart();

  const [moberror, setmobError] = useState();
  const [loading, setloading] = useState(false);
  const [loadingerror, setloadingerror] = useState(false);
  const [isstatus, issetStatus] = useState();

  const validateMobileNumber = (number) => {
    // Example validation: 10 digits mobile number
    const regex = /^\d{10}$/;
    return regex.test(number);
  };


  const handleChange = (e) => {
    
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
        if (!validateMobileNumber(formData.Name)){
          setmobError("Error: Please enter 10 digit Mobile no.");
        }
        else{
          issetStatus(true);
          setmobError();

              try {
                setloading(true);
                const res = await axios.get(`https://www.lelofood.xyz/posts/orders/${formData.Name}`);
                issetStatus(false);
                setorderbymob(res.data);
                //console.log(res.data[0]);
        
              } catch (err) {
                setloadingerror(true);
                console.log(err);
              }

          
        } 
  };

  const calculateTotal2 = () => {
    return orderbymob?.cart.reduce((total, item) => total + item.post.price * item.amount, 0); 
  };
  const calculateTotal = (cart) => {
    return cart.reduce((total, item) => total + item.post.price * item.amount, 0); 
  };

 
  return (
    <div className="chatBox" style={{overflow:"scroll", height:"85dvh"}}>
       <form onSubmit={handleSubmit} >
      <div className="input"> 
     
        <Input
                      name="Name"
                      label="Enter Correct Mobile No."
                      rows="1"
                      autoFocus
                      handleChange={handleChange}  
                      inputwidth= {210}        
        />
        <Button
                      type="submit"
                      variant="contained"
                      color="primary"

                        style={{fontWeight:"600", backgroundColor: '#FF6600'}}
                        

                    >
                      search
        </Button>
        
      </div>
      {moberror  && (
                    <div  style={{color: "red", fontWeight:"600", marginLeft: "8%"}}>{moberror}</div>     
                  )
      }
      <div style={{marginLeft: "10%", marginTop: "25px"}}>Always Click search button to see latest order</div>
      {
        isstatus && <div style={{marginLeft: "45%", marginTop: "25px"}}><CircularProgress/></div> 
      }
      </form>
     
       {orderbymob
       ?
       
       <>
       <div className="bill">All Orders</div>
       {orderbymob.map((item)=>(
        <div>
        <div style={{overflow:"scroll", width:"90vw", marginLeft: "1%", marginTop:"10px"}}>
                <div className="item" style={{fontSize: "18px",fontWeight: "600"}}>Shop: {item.seller}</div>
                <div className="item">
                           
                           <div style={{fontSize: "18px", width:"38%",  fontWeight: "600"}}>Name</div>
                           <div style={{fontSize: "18px", width:"18%", fontWeight: "600"}}>No.</div>
                           <div style={{fontSize: "18px", width:"25%", fontWeight: "600"}}>Price</div>
                             
                </div>
                {
                    item?.cart?.map((item, index) => (
                       
                        <div key={index} className="item">
                           
                             <div style={{fontSize: "15px", width:"45%"}}>{item?.post?.name}</div>
                             <div style={{fontSize: "15px", width:"10%"}}>{item?.amount}</div>
                             <div style={{fontSize: "15px", width:"25%"}}>Rs. {item?.post?.price * item?.amount}</div>
                               
                       </div>
                    ))
                }
                {item.writecake && <div className="item" style={{fontSize: "15px"}}>Write: {item.writecake}</div>}
                {item.DeliveryDateTime !== " " && <div className="item" style={{fontSize: "15px"}}>Delivery Date: {item.DeliveryDateTime}</div>}
             
        </div>
        <div className="total">Total Rs. {calculateTotal(item?.cart)}</div>
        </div>))}
        
       </>
       
       :<div className="total" style={{marginBottom: "50vh"}}>Search placed order!!</div>}           




      
           
    </div>
  );
};
export default Allorders;
