import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';


import Post from './Post/Post';
import useStyles from './styles';







const Posts = ({  post23, ishome}) => {
  
  var posts = post23;
  
  //const posts = useSelector((state) => state.posts);
   //posts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const classes = useStyles();

  return (
    !posts.length ? <CircularProgress /> : (
      <Grid className={classes.container} container alignItems="stretch" spacing={3} style={{marginBottom: "20vh"}}>
        {/*!ishome &&
        (<Grid key={posts[0]._id} item xs={12} sm={6} md={6} lg={3}>
            <Post post={posts[0]}  ishome={true} />
        </Grid>
        )*/}
        
        {posts.map((post) => (
          <Grid key={post._id} item xs={ishome ? 12: 6 } sm={6} md={6} lg={3}>
            <Post post={post}  ishome={ishome}/>
          </Grid>
        ))
        }
      </Grid>
      
      
    )
  );
};

export default Posts;