
import React, {useState} from 'react';
import { CardMedia, Button, CircularProgress, IconButton } from '@material-ui/core';

//import { useDispatch } from 'react-redux';
import { Link} from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useCart } from '../../../cartContext/CartContext';

//import { likePost, deletePost } from '../../../actions/posts';
import useStyles from './styles';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useNavigate } from 'react-router-dom';


import Toaster from '../../Toaster/Toaster';


const Post = ({ post, ishome}) => {
  const { addToCart } = useCart();
  //const dispatch = useDispatch();
  const classes = useStyles();
  
  //console.log(post);
  const [amount, setamount] = useState(1);

  const setIncrease= ()=>{
    setamount(amount + 1);
  };
  const setDecrease= ()=>{
    amount > 1 ? setamount(amount - 1) : setamount(1);
  };

  //const user = JSON.parse(localStorage.getItem('profile'));

  const [toasterMessage, setToasterMessage] = useState('');

  const showToast = (message) => {
    setToasterMessage(message);
    // Automatically remove the toaster message after 2 seconds
    setTimeout(() => {
      setToasterMessage('');
    }, 1200);
  };

  const navigate = useNavigate();
  return (
    !post ? <CircularProgress /> :
    (
    <div onClick={() => navigate(post.link)} className={classes.card} style={{boxShadow: ishome? "0px 3px 8px rgba(0, 0, 0, 0.2)": null}}>
      <CardMedia className={ ishome? classes.media2: classes.media} image={post.selectedFile || 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png'} title={post.title} />

      <div className={classes.card1}>
            <div style={{fontSize: "18px"}}>{post.name}</div>
            
            {ishome? 
            (null):
                  (<div className={classes.card2}>
                    <IconButton size="small" onClick={() => setDecrease()}> 
                     <RemoveIcon fontSize="small"/>
                    </IconButton>
                    <div style={{fontSize: "18px"}}>{amount}</div>
                    <IconButton size="small" onClick={() => setIncrease()}>
                     <AddIcon fontSize="small"/>
                    </IconButton>
                  </div>)
            }
            
      </div>
      {ishome? <div style={{fontSize: "13px", marginTop: '3px',marginLeft:'7px',color:"grey"}}>{post.price}</div> : null}
      <div className={classes.card1} style={{marginTop: "10px"}}>
        {ishome
        ?null 
        :(<>
            <div style={{fontSize: "18px"}}>Rs. {post.price}</div>
            <Button  onClick={() => {addToCart({post, amount}); showToast('Added, Go to Cart')}} size="small" variant="contained" color ="primary" style={{backgroundColor: '#FF6600'}}>Add<ShoppingCartIcon fontSize="small"/></Button>
          </>
       )}
         {toasterMessage && (
        <Toaster
          message={toasterMessage}
          onClose={() => setToasterMessage('')}
        />
      )}
      </div>
    </div>
    )
  );
};

export default Post;