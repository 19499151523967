import React, { useState, useEffect } from "react";
import "./ContactUs.css";
//import emailgif from "./email-gif1.gif";
import Input from "../Input";
import {useNavigate} from 'react-router-dom';

import {  Button, IconButton, CircularProgress } from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import { useCart } from '../../cartContext/CartContext';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Link} from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import axios from "axios";
import RoomIcon from '@material-ui/icons/Room';
import io from 'socket.io-client';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 130,
  },
}));

const socket = io('https://www.lelofood.xyz');

const initialState = {
  Name: "",
  Email: "",
  writecake: "",
  DeliveryDate: "",
  Time: ""
};

const ContactUs = ({iscakebill, seller}) => {
  

  console.log(iscakebill);
  console.log(seller);
 


  const { cart } = useCart();
  const { removeItem } = useCart();
  const { emptyCart } = useCart();

  const { handleGetLocation } = useCart();
  //const { error } = useCart();
  const { location } = useCart();

  //const [amount, setamount] = useState(1);
  //const [total, settotal] = useState(0);

  const [loading, setloading] = useState(false);
  const [loadingerror, setloadingerror] = useState(false);

  const history = useNavigate();
  const [formData, setFormData] = useState(initialState);
 


  //const [latitude, setlatitude] = useState(null);
  //const [longitude, setlongitude] = useState(null);


  const [moberror, setmobError] = useState();

  const validateMobileNumber = (number) => {
    // Example validation: 10 digits mobile number
    const regex = /^\d{10}$/;
    return regex.test(number);
  };

  /*const geo = navigator.geolocation;
  geo.getCurrentPosition(userCoords);
  function userCoords(position){
    let userlatitude = position.coords.latitude;
    let userlongitude = position.coords.longitude;
    setlatitude(userlatitude );
    setlongitude(userlongitude );
    console.log("check in start" + userlongitude );
  }*/

  //const currentUser  = null;
  const handleSubmit = async (e) => {
    e.preventDefault();

    const orders = {
      mobile: formData.Name,
      status: "Order placed",
      address: formData.Email,
      GPS: "(" + location.latitude +"," + location.longitude + ")",
      cart: cart,
      seller: seller,
      writecake: formData.writecake,
      DeliveryDateTime: formData.DeliveryDate +" "+ formData.Time,
      deliveryBoy: "none",
    };
    

        if (!validateMobileNumber(formData.Name)){
          setmobError("Error: Please enter 10 digit Mobile no.");
        }
        else{
          setmobError();

              try {
                setloading(true);
                const res = await axios.post("https://www.lelofood.xyz/posts/orders", orders);
                socket.emit('newOrder', res.data);
                //console.log(res);
                //console.log(orders);
                // Notify all clients of the update
                
                if(res.data){
                  emptyCart();
                  history('/status');

                }
          
              } catch (err) {
                setloadingerror(true);
                console.log(err);
              }

          
        } 
  };

  const handleChange = (e) => {
    
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const calculateTotal = () => {
    return cart.reduce((total, item) => total + item.post.price * item.amount, 0); 
  };
  const classes = useStyles();
 
  return (
    <div className="chatBox">
            <div className="contactContainer">
              <form onSubmit={handleSubmit} >
                <div className="rightContainer">
                  
                    <Input
                      name="Name"
                      label="Enter Correct Mobile No."
                      rows="1"
                      autoFocus
                      handleChange={handleChange}
                      inputwidth= {320}
                     
                    />
                  
                  {moberror  && (
                    <div style={{color: "red", fontWeight:"600"}}>{moberror}</div>     
                  )}

                  
                    <Input
                      name="Email"
                      label="Enter Address"
                      rows="2"
                      autoFocus
                      handleChange={handleChange}
                      inputwidth= {320}
                    />

                  {iscakebill ?
                <div style={{display: "flex", flexDirection: "row"}}>
                        <TextField
                          name="DeliveryDate"
                          label="Delivery Date"
                          type="date"
                          onChange={handleChange}
                          required
                          autoFocus
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <TextField
                          name="Time"
                          label="Time"
                          type="time"
                          onChange={handleChange}
                          autoFocus
                          
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        </div>
                        : null
                  }
                 {iscakebill ? 
                    <Input
                      name="writecake"
                      label="Write on Cake"
                      rows="1"
                      autoFocus
                      handleChange={handleChange}
                      inputwidth= {320}
                    /> 
                    
                  : null
                  }
                
                    
                
                  <span><Radio
                    value="a"
                    name="radio-button-demo"
                    onClick={() => handleGetLocation()}
                 /> 
                 <IconButton size="small" onClick={() => handleGetLocation()} style={{color: "black"}}>
                 <RoomIcon style={{color: "red"}}/>Share Location GPS
                 </IconButton> 
                 
                 {location.latitude && location.longitude && (
                        
                        <span style={{color: "red", }}>(Shared)</span>
                        
                  )}
                
                 </span>
                  
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    
                      style={{marginLeft: "50vw", fontWeight:"600", marginTop: '72vh', backgroundColor: '#FF6600', position:"absolute"}}
                    
                  >
                    order
                  </Button>
                  <Button
                    
                    onClick={() => history(-1)}
                    variant="contained"
                    color="primary"
                    
                      style={{marginLeft: "15vw", fontWeight:"600", marginTop: '72vh', backgroundColor: '#FF6600', position:"absolute"}}
                    
                  >
                    Back
                  </Button>


                  {loading?(

                        <Button
                        variant="contained"
                        color="primary"
                        style={{marginLeft: "15vw", marginRight: "10vw", fontWeight:"600", marginTop: '37vh', backgroundColor: '#FF6600', position:"absolute"}} 
                        >
                          {loadingerror?(<>Something Wrong: check network or refresh page</>)
                              :<><CircularProgress color="black" style={{marginRight: "10px"}}/>Placing Order...</>
                          }
                        
                        </Button>
                  ):null}

                </div>
              </form>
              <div className="bill">Bill Summary</div>
              
              <div style={{overflow:"scroll", height: iscakebill ? "13vh" : "30vh", width:"90vw", marginLeft: "1%", marginTop:"1px"}}>
                <div className="item">
                           
                           <div style={{fontSize: "18px", width:"38%",  fontWeight: "600"}}>Name</div>
                           <div style={{fontSize: "18px", width:"18%", fontWeight: "600"}}>No.</div>
                           <div style={{fontSize: "18px", width:"25%", fontWeight: "600"}}>Price</div>
                             
                </div>
                {
                    cart?.map((item, index) => (
                       
                        <div key={index} className="item">
                           
                             <div style={{fontSize: "18px", width:"45%"}}>{item.post.name}</div>
                             <div style={{fontSize: "18px", width:"10%"}}>{item.amount}</div>
                             <div style={{fontSize: "18px", width:"25%"}}>Rs. {item.post.price * item.amount}</div>
                             <IconButton style={{color: "red"}} size="small" onClick={() => removeItem(item.post._id)}>
                                   <DeleteForeverIcon fontSize="small"/>
                             </IconButton>   
                       </div>
                    ))
                }
             
              </div>
              <div className="total">Total Rs. {calculateTotal()}</div>
              
               </div> 
           
    </div>
  );
};
export default ContactUs;
