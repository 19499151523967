import {makeStyles} from '@material-ui/core/styles';

import {deepPurple} from '@material-ui/core/colors';

export default makeStyles((theme) =>({

appBar: {
borderRadius: 0,
margin: '0px 0px 20px 0px',
display: 'flex',
flexDirection: 'row',
justifyContent: 'space-between',
alignItems: 'center',
padding: '10px 50px',

},
appBar1: {
   /* margin: '0px 0px 20px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 10px',*/


    borderRadius: 0,
    margin: "0px 0px 20px 0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "8%",
    padding: '0 10px',
    
},
heading: {
fontWeight: '800',
color: '#FF6600',
textDecoration: 'none',
},
image: {

marginleft: '15px',
},
toolbar: {

display: 'flex',

JustifyContent: 'flex-end',
 width: '400px',
},

toolbar1: {

    display: 'flex',
    flexDirection: 'column',
    JustifyContent: 'flex-end',
     width: '190px',

    height: '290px',
  
  position: 'absolute',

  border: '3px solid gainsboro',
  backgroundColor: 'white',
  marginLeft: '40%',
  zIndex: 1000
},
profile:{
    display: 'flex',

justifyContent: 'space-between',

width: '300px',
},
profile2:{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: '20px',
    width: '150px',
},
profile3:{
    display: 'flex',

justifyContent: 'space-between',
marginTop: '10px',
width: '150px',
},
profileName:{
    display: 'flex',

},

userName: {

display: 'flex', 
alignItems: 'center',
}, 
brandContainer: { 
display: 'flex',
alignItems: 'center',
},
purple: {

color: theme.palette.getContrastText(deepPurple[500]),
 backgroundColor:deepPurple[500],
},
}
)
);

