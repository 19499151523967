import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  media: {
    height: 100,
    paddingTop: '56.25%',
    borderRadius: '15px 15px 0 0',
    
    
  },

  media2: {
    height: 50,
    borderRadius: '15px 15px 0 0',
    
    paddingTop: '36.25%',
   
  },
 
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    position: 'relative',
    borderRadius: '15px',
    
  },
  card1: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems:'center', 
    fontWeight: 'bold',
    marginLeft:'7px',
    marginTop: '3px'
   },
   card2: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems:'center', 
    
   },

  
 
 


 

 
 
});
