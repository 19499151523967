// src/Toaster.js
import React, { useEffect } from 'react';
import './Toaster.css'; // We'll add styles later
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const Toaster = ({ message, onClose }) => {
  useEffect(() => {
    // Set up a timer to automatically close the toaster after 2 seconds
    const timer = setTimeout(() => {
      onClose();
    }, 1200);

    // Clear the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="toaster">
      {message}<ShoppingCartIcon fontSize="small"/>
    </div>
  );
};

export default Toaster;
