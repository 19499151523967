
import React , {useState}from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Navbar from './components/NavBar/Navbar';
import Home from './components/Home/Home';
import Auth from './components/Auth/Auth';
import ContactUs from './components/ContactUs/ContactUs';
import Status from './components/Status/Status';
import Admin from './components/Admin/Admin';
import Contact from './components/Contact';

import { CartProvider } from './cartContext/CartContext';
import Allorders from './components/Allorders/Allorders';


//import portfolioImg01 from "../src/images/burger.png";
import portfolioImg01 from "../src/images/burger.png";
import portfolioImg02 from "../src/images/vegbiryani.png";
import portfolioImg03 from "../src/images/pizza.png";
import portfolioImg04 from "../src/images/paneerpizza.png";
import portfolioImg05 from "../src/images/sandwich.png";
import portfolioImg06 from "../src/images/Munchurian.png"; 
import portfolioImg07 from "../src/images/cake.png"; 

import portfolioImg08 from "../src/images/cake1.png";
import portfolioImg09 from "../src/images/cake2.png";
import portfolioImg10 from "../src/images/cake3.png";
import portfolioImg11 from "../src/images/cake4.png";
import portfolioImg12 from "../src/images/cake5.png";

import portfolioImg14 from "../src/images/taste.jpg";
import portfolioImg15 from "../src/images/princecake.jpg";
import portfolioImg16 from "../src/images/biryanihouse.jpg";
import portfolioImg17 from "../src/images/nan.jpg";
import portfolioImg18 from "../src/images/momos.jfif";

import portfolioImg19 from "../src/images/momo.png";
import portfolioImg20 from "../src/images/chiknbiryani.png";

import portfolioImg26 from "../src/images/26.jpg";
import portfolioImg27 from "../src/images/27.jpg";
import portfolioImg28 from "../src/images/28.png";
import portfolioImg29 from "../src/images/29.png";
import portfolioImg30 from "../src/images/30.png";
import portfolioImg31 from "../src/images/31.png";

import portfolioImg32 from "../src/images/32.png";
import portfolioImg33 from "../src/images/33.png";
import portfolioImg34 from "../src/images/34.png";
import portfolioImg35 from "../src/images/35.png";
import portfolioImg36 from "../src/images/36.png";
import portfolioImg37 from "../src/images/37.png";
import portfolioImg38 from "../src/images/38.png";
import portfolioImg39 from "../src/images/39.png";
import portfolioImg40 from "../src/images/40.png";
import portfolioImg41 from "../src/images/41.png";
import portfolioImg42 from "../src/images/42.png";
import portfolioImg43 from "../src/images/43.png";
import portfolioImg44 from "../src/images/44.png";
import portfolioImg45 from "../src/images/45.png";
import portfolioImg46 from "../src/images/46.png";
import portfolioImg47 from "../src/images/47.jfif";
import portfolioImg48 from "../src/images/48.jfif";
import portfolioImg49 from "../src/images/49.png";
import portfolioImg50 from "../src/images/50.png";
import portfolioImg51 from "../src/images/51.jpg";
import portfolioImg52 from "../src/images/52.jpg";
import portfolioImg53 from "../src/images/53.png";
import portfolioImg54 from "../src/images/54.png";
import portfolioImg55 from "../src/images/55.png";
import portfolioImg56 from "../src/images/56.png";
import portfolioImg57 from "../src/images/57.jpg";
import portfolioImg58 from "../src/images/pannermasala.png";
import portfolioImg59 from "../src/images/naan.jpg";
import portfolioImg60 from "../src/images/60.png";
import portfolioImg61 from "../src/images/61.png";
import portfolioImg62 from "../src/images/62.png";
import portfolioImg63 from "../src/images/63.png";



const allpost = [
    {
      _id: "1",
      selectedFile: portfolioImg01,
      name: "Burger",
      price: 35
      },
    {
      _id: "3",
      selectedFile: portfolioImg03,
      name: "Pizza",
      price: 99
    },
    {
      _id: "4",
      selectedFile: portfolioImg04,
      name: "Paneer Pizza",
      price: 115
    },
    {
      _id: "5",
      selectedFile: portfolioImg05,
      name: "Sandwich",
      price: 70
    },
    {
      _id: "53",
      selectedFile: portfolioImg53,
      name: "Pizza",
      price: 99
    },
    {
      _id: "54",
      selectedFile: portfolioImg54,
      name: "Pizza",
      price: 99
    },
    {
      _id: "55",
      selectedFile: portfolioImg55,
      name: "Pizza",
      price: 99
    },
    {
      _id: "56",
      selectedFile: portfolioImg56,
      name: "Pizza",
      price: 99
    }
   
  ];
  
  const allpost2 = [
    {
      _id: "8",
      selectedFile: portfolioImg08,
      name: "Choco",
      price: 350
      },
    {
      _id: "9",
      selectedFile: portfolioImg09,
      name: "Redcake",
      price: 350
    },
    {
      _id: "10",
      selectedFile: portfolioImg10,
      name: "Blackchoco",
      price: 320
    },
    {
      _id: "11",
      selectedFile: portfolioImg11,
      name: "Heart",
      price: 380
    },
    {
      _id: "12",
      selectedFile: portfolioImg12,
      name: "Lightchoco",
      price: 380
    },
    {
        _id: "13",
        selectedFile: portfolioImg07,
        name: "Whitecake",
        price: 350
      },
      {
        _id: "32",
        selectedFile: portfolioImg32,
        name: "Choco",
        price: 320
      },
      {
        _id: "33",
        selectedFile: portfolioImg33,
        name: "Choco",
        price: 320
      },
      {
        _id: "34",
        selectedFile: portfolioImg34,
        name: "Choco",
        price: 320
      },
      {
        _id: "35",
        selectedFile: portfolioImg35,
        name: "Choco",
        price: 320
      },
      {
        _id: "36",
        selectedFile: portfolioImg36,
        name: "Choco",
        price: 320
      },
      {
        _id: "37",
        selectedFile: portfolioImg37,
        name: "Choco",
        price: 320
      },
      {
        _id: "38",
        selectedFile: portfolioImg38,
        name: "Choco",
        price: 320
      },
      {
        _id: "39",
        selectedFile: portfolioImg39,
        name: "Choco",
        price: 320
      },
      {
        _id: "40",
        selectedFile: portfolioImg40,
        name: "Choco",
        price: 320
      },
      {
        _id: "41",
        selectedFile: portfolioImg41,
        name: "Choco",
        price: 320
      },
      {
        _id: "42",
        selectedFile: portfolioImg42,
        name: "Choco",
        price: 320
      },
      {
        _id: "43",
        selectedFile: portfolioImg43,
        name: "Choco",
        price: 320
      },
      {
        _id: "44",
        selectedFile: portfolioImg44,
        name: "Choco",
        price: 320
      }
   
  ];

  const allrestaurents = [
    {
      _id: "14",
      selectedFile: portfolioImg14,
      name: "Taste it Cafe",
      price: "Burger . Pizza . Sandwitch . Pasta",
      link: "/tasteit"
      },
    {
      _id: "15",
      selectedFile: portfolioImg15,
      name: "Prince Sweets & Cake Palace",
      price: "Cake for Celebration . Sweets . Pasties",
      link: "/cake"
      
    },
    {
      _id: "16",
      selectedFile: portfolioImg16,
      name: "Viryani House",
      price: "Veg Biryani . Chicken Biryani . Egg Roll ...",
      link: "/biryanihouse"
    },
    {
      _id: "17",
      selectedFile: portfolioImg17,
      name: "Ratnagiri",
      price: "Paneer Masala. Tandoor Roti . Nan ...",
      link: "/ratnagiri"
    },
    {
      _id: "18",
      selectedFile: portfolioImg18,
      name: "Momo Magic",
      price: "Momo . Burger",
      link: "/momomagic"
    }
  ];

  const biryanihousepost = [
    {
      _id: "19",
      selectedFile: portfolioImg02,
      name: "Veg.Biryani",
      price: 110
      },
      {
        _id: "20",
        selectedFile: portfolioImg20,
        name: "Chikn.Biryani",
        price: 120
      },
      {
        _id: "45",
        selectedFile: portfolioImg45,
        name: "Momo",
        price: 40
      },
      {
        _id: "46",
        selectedFile: portfolioImg46,
        name: "Momo",
        price: 40
      },
      {
        _id: "49",
        selectedFile: portfolioImg49,
        name: "Momo",
        price: 40
      },
      {
        _id: "50",
        selectedFile: portfolioImg50,
        name: "Momo",
        price: 40
      }
   
  ];

  const ratnagiripost = [
    {
      _id: "22",
      selectedFile: portfolioImg02,
      name: "Veg.Biryani",
      price: 110
      },
    {
      _id: "23",
      selectedFile: portfolioImg06,
      name: "Munchurian",
      price: 60
    },
    {
      _id: "58",
      selectedFile: portfolioImg58,
      name: "Paneer",
      price: 140
    },
    {
      _id: "59",
      selectedFile: portfolioImg59,
      name: "Naan",
      price: 40
    },
    {
      _id: "60",
      selectedFile: portfolioImg60,
      name: "Sandwitch",
      price: 40
    },
    {
      _id: "61",
      selectedFile: portfolioImg61,
      name: "Veg.Biryani",
      price: 110
    },
    {
      _id: "62",
      selectedFile: portfolioImg62,
      name: "Veg.Biryani",
      price: 110
    },
    {
      _id: "63",
      selectedFile: portfolioImg63,
      name: "Momo",
      price: 40
    }
  ];

  const momomagicpost = [
    {
      _id: "24",
      selectedFile: portfolioImg01,
      name: "Burger",
      price: 35
      },
    {
      _id: "25",
      selectedFile: portfolioImg19,
      name: "Momo",
      price: 40
    },
    {
      _id: "28",
      selectedFile: portfolioImg28,
      name: "Burger",
      price: 35
      },
    {
      _id: "29",
      selectedFile: portfolioImg29,
      name: "Momo",
      price: 40
    },
    {
      _id: "30",
      selectedFile: portfolioImg30,
      name: "Burger",
      price: 35
      },
    {
      _id: "31",
      selectedFile: portfolioImg31,
      name: "Momo",
      price: 40
    }
  ];
  
 
const App = () => {
  const [seller, setseller] = useState();
 
return(
    <CartProvider><BrowserRouter>
    <Navbar />
    <Routes>
     <Route path="/" exact element = {<Home post23={allrestaurents} ishome = {true} iscakebill = {false}/>} />
     <Route path="/tasteit" exact element = {<Home post23={allpost} ishome = {false} iscakebill = {false} setseller={setseller} sellername={"tasteit"}/> } />
     <Route path="/cake" exact element = {<Home post23={allpost2} ishome = {false} iscakebill = {true} setseller={setseller} sellername={"cake"}/>} />
     <Route path="/biryanihouse" exact element = {<Home post23={biryanihousepost} ishome = {false} iscakebill = {false} setseller={setseller} sellername={"biryanihouse"}/>} />
     <Route path="/ratnagiri" exact element = {<Home post23={ratnagiripost} ishome = {false} iscakebill = {false} setseller={setseller} sellername={"ratnagiri"}/>} />
     <Route path="/momomagic" exact element = {<Home post23={momomagicpost} ishome = {false} iscakebill = {false} setseller={setseller} sellername={"momomagic"}/>} />
     <Route path="/auth" exact element = {<Auth/>} />
     <Route path="/contactus" exact element = {<ContactUs iscakebill = {false} seller={seller}/>} />
     <Route path="/cakebill" exact element = {<ContactUs iscakebill = {true} seller={seller}/>} />
     <Route path="/status" exact element = {<Status/>} />
     <Route path="/allorders" exact element = {<Allorders/>} />
     <Route path="/seller" exact element = {<Admin isearning = {true}/>} />
     <Route path="/earning" exact element = {<Admin isearning = {false}/>} />
     <Route path="/contact" exact element = {<Contact />} />
     
    </Routes>
    </BrowserRouter></CartProvider>
 
);
};

export default App;